import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {inject, PLATFORM_ID} from "@angular/core";
import {AuthenticationService} from "@core/services/authentication.service";
import {Router} from "@angular/router";
import {catchError, EMPTY, from, switchMap} from "rxjs";
import {isPlatformServer} from "@angular/common";
import {AuthenticationEndPoint} from "@core/const";


export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);
  const WHITE_LIST_URLS: string[] = [
    AuthenticationEndPoint.LOGIN,
    AuthenticationEndPoint.REFRESH,
    AuthenticationEndPoint.VALIDATE
  ]
  if (isPlatformServer(platformId)) {
    return next(req);
  }
  if (req.url.includes("/api/") && !WHITE_LIST_URLS.find((WHITE_LIST_URL) => req.url.includes(WHITE_LIST_URL))) {
    if (!authenticationService.isAccessTokenValid()) {
      return from(authenticationService.refresh()).pipe(
        switchMap(() => next(req)),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401 || err.status === 403) {
            return from(router.navigate(['/login'])).pipe(switchMap(() => EMPTY));
          }
          return EMPTY;
        })
      );
    }
  }
  return next(req);
};
