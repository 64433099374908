import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID,} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {NavbarComponent} from './components/navbar/navbar.component';
import {ProfileDropdownComponent} from './components/profile-dropdown/profile-dropdown.component';
import {navbarItems} from './app.props';
import {I18nService} from '@core/services/i18n/i18n.service';
import {ToastModule} from "primeng/toast";
import {isPlatformBrowser, NgClass} from "@angular/common";
import {Subscription} from "rxjs";
import {AuthenticationService} from "@core/services/authentication.service";
import {PermissionService} from "@core/services/permission.service";
import {NavBarItemModel} from "@core/models/component-models/navbar.model";
import {ProgressSpinnerComponent} from "@shared/progress-spinner/progress-spinner.component";
import {SpinnerService} from "@core/services/spinner.service";
import {SignalService} from "@core/services/signal.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, ProfileDropdownComponent, ToastModule, ProgressSpinnerComponent, NgClass],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  navbarItems: NavBarItemModel[] = [];
  isLoginPage: boolean = true;
  subscription: Subscription = new Subscription();
  username: string | null = null;

  constructor(private i18n: I18nService,
              private router: Router,
              private cd: ChangeDetectorRef, // Inject ChangeDetectorRef
              private auth: AuthenticationService,
              public spinnerService: SpinnerService,
              private permissionService: PermissionService,
              private signalService: SignalService,
              @Inject(PLATFORM_ID) private readonly platformId: Object) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.auth.isUserAuthenticated()
    this.i18n.setUpLanguages();
    if (isPlatformBrowser(this.platformId)) {
      const body = document.getElementsByTagName('body')[0]
      this.i18n.onLangChange().subscribe((res) => {
        body.className = res.lang === 'ar' ? 'rtl' : ''
        this.cd.detectChanges();
      })
    }
    this.checkIfLoginPage();
  }

  checkIfLoginPage(): void {
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isLoginPage = event.url === "/login";
          if (!this.isLoginPage) {
            this.navbarItems = this.buildNavbar(navbarItems);
            this.username = this.signalService.employeeName();
          }
        }
      })
    );
  }

  buildNavbar(items: NavBarItemModel[]): NavBarItemModel[] {
    return items
      .map(item => {
        if (item.items && item.items.length > 0) {
          const filteredChildren = this.buildNavbar(item.items);
          if (filteredChildren.length > 0) {
            return {
              ...item,
              items: filteredChildren
            };
          }
          return null;
        } else {
          return this.permissionService.isPermitted(item.permission + '_VIEW') ? item : null;
        }
      })
      .filter(item => item !== null) as NavBarItemModel[];
  }
}
