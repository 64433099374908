import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "@core/services/authentication.service";
import {inject, PLATFORM_ID} from "@angular/core";
import {isPlatformServer} from "@angular/common";
import {AuthenticationEndPoint} from "@core/const";

export const authenticationGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authenticationService: AuthenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId) || state.url === AuthenticationEndPoint.LOGIN) {
    return true;
  }
  const isUserAuthenticated = await authenticationService.isUserAuthenticated();
  if (!isUserAuthenticated) {
    router.navigate(['/login']);
  }
  return isUserAuthenticated;
};
